<ng-container *ngIf="loading">
  <div class="flex-skeleton desktop-skeleton">
    <div class=" flex flex-1">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
          {
            height: '250px',
            width: '100%'
          }
      "></ngx-skeleton-loader>
    </div>
    <div class=" flex flex-2">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '800px',
                  width: '100%'

    }
"></ngx-skeleton-loader>
    </div>

  </div>
  <div class="flex-skeleton flex-mobile-skeleton">
    <div class="flex">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
          {
            height: '400px',
            width: '100%'
          }
      "></ngx-skeleton-loader>
    </div>
  </div>
</ng-container>

<div class="feedback-survey" *ngIf="!loading && questionnaire">
  <app-sidebar overviewTitle="{{'QUESTIONS' | translate}}"
               [sections]="sections"
               (scrollTo)="scrollTo($event)"
               [nextNavigatorControl]="nextNavigationControl"
               [previousNavigatorControl]="previousNavigatorControl">
  </app-sidebar>
  <div class="feedback-survey__content">
    <app-section-header [title]="questionnaire.title" [unit]="questionnaire.unit.title">
    </app-section-header>
    <div class="feedback-survey__quizes">
      <div class="feedback-survey__quiz" *ngFor="let question of questionnaire.questions; let i = index;"
           id="{{question.slug}}">
        <div class="feedback-survey__quiz-title">{{"QUESTION" | translate}} {{i + 1}}/{{questionnaire.questions.length}}</div>
        <div class="feedback-survey__quiz-item">
          <div class="feedback-survey__quiz-item-title">{{question.title}}</div>
          <ng-container *ngIf="question.question_type === 'single'">
            <mat-radio-group class="feedback-survey__items" (change)="onSelect(question, $event)">
              <mat-radio-button class="feedback-survey__quiz-answer"
                                *ngFor="let option of question.options; let i = index" [value]="option.slug">
                <div class="feedback-survey__quiz-answer-label">{{option.name}}</div>
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-container *ngIf="question.question_type === 'checklist'">
            <div class="checkbox-group">
              <mat-checkbox
                (change)="onChecklistSelect(question, $event, option)"
                class="feedback-survey__quiz-answer"
                *ngFor="let option of question.options; let i = index" [value]="option.slug">
                <div class="feedback-survey__quiz-answer-label">{{option.name}}</div>
              </mat-checkbox>
            </div>
          </ng-container>
          <ng-container *ngIf="question.question_type === 'dragandorder'">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i)" >
              <div class="example-box" *ngFor="let option of question.options; let i = index;" cdkDrag>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                 <div class="drag-drop-item"> <img src="assets/img/grip-vertical.svg" class="grip-vertical" alt=""> {{option.name}} </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="question.question_type === 'match'">
            <div class="flex gap-x-3">
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i)">
                <div class="example-box content-item cursor-default" *ngFor="let content of question.content; let i = index"> {{content}} </div>
              </div>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i)" >
                <div class="example-box" *ngFor="let option of question.options; let i = index;" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  <div class="drag-drop-item"> <img src="assets/img/grip-vertical.svg" class="grip-vertical" alt=""> {{option.name}} </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="feedback-survey__submit">
        <button mat-button color="primary" [class.disabled]="answeredQuestions.length !== questionnaire.questions.length"
                (click)="onSubmitQuestionnaire(questionnaire)">{{"SUBMIT" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
