<div class="quiz" *ngIf="block" id="{{block.slug}}">
  <h1>{{block.title}}</h1>

  <ng-container *ngIf="block.questionnaire">
    <div class="quiz-item" *ngFor="let question of block?.questionnaire?.questions; let i = index;" >
      <div class="quiz-title">{{question.title}}</div>


      <ng-container *ngIf="question.question_type === 'single'">
        <mat-radio-group class="items-container">
          <mat-radio-button class="quiz-answer" *ngFor="let option of question.options; let i = index" [value]="option.slug" (change)="onSelect(question, option)">
            <div class="quiz-answer-label">{{option.name}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>

      <ng-container *ngIf="question.question_type === 'match'">
        <div class="flex gap-x-3">
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i, question)">
            <div class="example-box content-item cursor-default" *ngFor="let content of question.content; let i = index"> {{content}} </div>
          </div>
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i, question)" >
            <div class="example-box" *ngFor="let option of question.options; let i = index;" cdkDrag>
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <div class="drag-drop-item"> <img src="assets/img/grip-vertical.svg" class="grip-vertical" alt=""> {{option.name}} </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="question.question_type === 'dragandorder'">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i, question)" >
          <div class="example-box" *ngFor="let option of question.options; let i = index;" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
             <div class="drag-drop-item"> <img src="assets/img/grip-vertical.svg" class="grip-vertical" alt=""> {{option.name}} </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="question.question_type === 'checklist'">
        <div class="checkbox-group">
          <mat-checkbox
          (change)="onChecklistSelect(question, $event, option)"
          class="feedback-survey__quiz-answer"
            *ngFor="let option of question.options; let i = index" [value]="option.slug">
            <div class="feedback-survey__quiz-answer-label">{{option.name}}</div>
          </mat-checkbox>
        </div>
      </ng-container>

      <button mat-button color="primary" class="quiz-btn" [disabled]="question.selectedOption === undefined && question.selectedOptions === undefined" (click)="onCheckAnswer(question, block.questionnaire?.show_answer)">{{"Submit" | translate}}</button>
    </div>
  </ng-container>
</div>

<ng-template #answerModal let-data>
    <mat-card>
      <div style="display: flex; gap: 12px">
        <div>
          <h2 class="title">{{data.question.title}}</h2>
        </div>
        <mat-icon style="color: #34A853; position: relative; top: 3px" class="check-circle" *ngIf="data.correct">check_circle</mat-icon>
        <mat-icon style="color: #DB4437; position: relative; top: 3px" class="cancel-circle" *ngIf="!data.correct">cancel</mat-icon>
      </div>
      <div>

        <h4 class="answer">{{"YOUR_ANSWER" | translate}}:
          <div *ngIf="isAnswerSlugArray(data.question)">
            <div class="question-response" *ngFor="let response of getUserAnswers(data); let i = index;">
              <span class="bold">{{response}}</span>
              <br />
            </div>
          </div>
          <div *ngIf="!isAnswerSlugArray(data.question)">
            <span class="bold">{{getUserAnswer(data)}}</span>
          </div>
        </h4>

        <h4 *ngIf="!data.correct && data.showAnswer" class="answer">{{"CORRECT_ANSWER" | translate}}:
          <div *ngIf="isAnswerSlugArray(data.question)">
            <div class="question-answer" *ngFor="let response of getCorrectAnswers(data); let i = index;">
              <span class="bold">{{response}}</span>
              <br />
            </div>
          </div>
          <div *ngIf="!isAnswerSlugArray(data.question)">
            <div *ngIf="data.showAnswer"> <span class="bold">{{getCorrectAnswer(data.question)}}</span> </div>
          </div>
        </h4>
      </div>

      <!-- <h2 class="title">{{data.question.title}}</h2>
      <h4 class="answer" 
        [ngClass]="{ 'right-answer': data.correct, 'wrong-answer': !data.correct }">
          {{data.correct ? "Right Answer" : "Wrong Answer"}}
      </h4> -->
    </mat-card>
</ng-template>



