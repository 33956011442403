import {
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Block, Question, QuestionAnswer, QuestionOption } from '../../../types/models';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CoreService } from '../../../services/core.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface DialogData {
  question: Question;
  correctOptions: QuestionOption[];
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @ViewChild('answerModal', { static: true }) answerModal: any;
  @Input() block: Block | undefined;

  constructor(
    private dialog: MatDialog,
    private coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  public isAnswerSlugArray(data): boolean {
    return !!data.selectedOptions
  }

  public onCheckAnswer(question: Question, showAnswer?: boolean) {
    let correctOptions: QuestionOption[] = [];
    let correct: boolean = false;
    if(question.question_type === "single") {
      const e = question.options.find((option) => option.correct);
      if(e)
        correctOptions = [e];
      correct = question.selectedOption.correct;
    }
    else if(question.question_type === "checklist") {
      correct = question.selectedOptions.every((option) => option.correct) && question.selectedOptions.length === question.options.filter((option) => option.correct).length;
      correctOptions = question.options.filter((option) => option.correct);
    }
    else if(question.question_type === "dragandorder" || question.question_type === "match") {

      for(let i = 1; i < question.options.length; i++) {
        if(question.options[i-1].order !== i) {
          correct = false;
          break;
        }
        correct = true;
      }
    }

    this.dialog.open(this.answerModal, {
      width: '600px',
      data: {
        question: question,
        correctOptions,
        showAnswer,
        correct
      },
    });
  }

  checkDragDropAnswer(myOrder: number[]) {
      let initOrder: any = [];
      myOrder.map((item, i) => {
        initOrder.push(i + 1);
      }) 
      return initOrder.every((value, index) => value === myOrder[index]);
    }
  
  drop(event: CdkDragDrop<string[]>, i: number, question: Question) {
    console.log("question", question);
    console.log("i", i);
    if(question) {
      let myOrder: number[] = [];
      moveItemInArray(question.options, event.previousIndex, event.currentIndex);
      question.options.map((option, index) => {
        if(option.order !== undefined)
          myOrder.push(option.order)
      })
     
      question.selectedOptions = myOrder.map((order) => question.options.find((option) => option.order === order)!);
      console.log("questionnaire.selectedOptions", question.selectedOptions);
    }
  }

  public getUserAnswers(data){
    if(data.question.question_type === "checklist") {
      return data.question.selectedOptions.map(e => e.name)
    }
    if(data.question.question_type === "dragandorder") {
      return data.question.selectedOptions.map(e => e.name)
    }
    if(data.question.question_type === "match" ) {
      return data.question.selectedOptions.map((e, i) => `${data.question.content[i]} - ${e.name}`)
    }
    return [];
  }

  public getUserAnswer(data: any) {
    if(data.question.question_type === "dragandorder" || data.question.question_type === "match" ) {

    }
    if(data.question.question_type === "single") {
      return data.question.selectedOption?.name;
    }
  
 
    return  data.question.selectedOption?.name;
   
  }

  public onSelect(question: Question, option: QuestionOption) {
    question.selectedOption = option;
  }

  public getCorrectAnswers(data){
    if(data.question.question_type === "checklist") {
      return data.question.options.filter(e => e.correct).map(e => e.name)
    }
    if(data.question.question_type === "dragandorder" ) {
      return ([...data.question.options]).sort((a, b) => a.order - b.order).map(e => e.name)
    }
    if(data.question.question_type === "match" ) {
      return ([...data.question.options]).sort((a, b) => a.order - b.order).map((e, i) => `${data.question.content[i]} - ${e.name}`)
    }
    return []
  }

  public getCorrectAnswer(data) {
    return data.options.find((x) => x.correct)?.name;
  }

  /* Checklist selected */
    public onChecklistSelect(
      question: Question,
      e: MatCheckboxChange,
      option: QuestionOption
    ) {
      console.log("question", question);
      console.log("e", e);
      console.log("option", option);

      // Find if this option is already in the list of answers
      if(!e.checked)
        question.selectedOptions = [...(question.selectedOptions ?? []).filter(e => e.slug !== option.slug), ];
      else {
        if((question.selectedOptions && question.selectedOptions.some(e => e.slug === option.slug)))
          return;

        question.selectedOptions = [...(question.selectedOptions ?? []), option];
      }
    }
}
