import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Course, CourseAssessment, Unit } from '../../types/models';
import { CoreService } from '../../services/core.service';
import { Title } from '@angular/platform-browser';
import { UnionService } from '../../services/union.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit, OnDestroy {
  public isAvailable = true;
  public loading = true;
  public loadingUnits = true;
  public course: Course | undefined;
  public units: Unit[] = [];
  public assessments: CourseAssessment[] = [];
  public subscriptions: Subscription[] = [];
  public show_answer = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private unionService: UnionService,
    private router: Router,
    private title: Title,
    private languageService: LanguageService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    // Retrieve the course slug from the URL
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.loading = true;
        this.loadingUnits = true;
        this.units = [];
        const slug: string | null = params['slug'];
        if (slug) {
          this.onLoadUnits(slug);
          this.onLoadCourse(slug);
          this.onLoadAssessment(slug);
        }
      })
    );

    this.subscriptions.push(
      this.unionService.languageChange.subscribe((countryCode) => {
        this.onLoadCourseByLanguage(this.course?.slug, countryCode);
      })
    );
  }

  /* Load the course by slug */
  public onLoadCourseByLanguage(slug, countryCode: string): void {
    let type = 'desktop';
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 768) {
      type = 'mobile';
    }
    const params = {
      type,
    };
    this.units = [];
    this.loading = true;
    this.loadingUnits = true;

    this.subscriptions.push(
      this.coreService.getCourseByLanguage(slug, countryCode, params).subscribe(
        (course) => {
          if(!course.slug){
            this.isAvailable = false;
            this.router.navigate([`/courses/not-found`]);
            return;
          }
          else 
            this.isAvailable = true;

          this.course = course;

          this.router.navigate([`/courses/${course.slug}`]);
          this.loading = false;
        }
      )
    );
  }

  /* Load the course */
  public onLoadCourse(slug: string): void {
    let type = 'desktop';
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 768) {
      type = 'mobile';
    }
    const params = {
      type,
    };
    this.subscriptions.push(
      this.coreService.getCourse(slug, params).subscribe(
        (course) => {
          this.languageService.setCode(course.language);
          this.course = course;
          this.title.setTitle(`${course.title} - Google Workspace Training`);
          this.loading = false;
          this.isAvailable = true;
          this.show_answer = course.show_answer;
        },
        () => {
          this.isAvailable = false;
          this.loading = false;
        }
      )
    );
  }

  /* Load units */
  public onLoadUnits(courseSlug) {
    const params = {
      course_slug: courseSlug,
    };
    this.coreService.getUnits(params).subscribe(
      (payload) => {
        this.units = payload.records;
        this.loadingUnits = false;
      },
      () => {
        this.units = [];
        this.loadingUnits = false;
      }
    );
  }

  /* Load the assessment */
  public onLoadAssessment(slug): void {
    const params = {
      course_slug: slug,
    };
    this.subscriptions.push(
      this.coreService.getCourseAssessments(params).subscribe((payload) => {
        this.assessments = payload.records;
      })
    );
  }

  /* Load LTI Config */
  public onLoadLtiConfig(assessment: CourseAssessment): void {
    this.subscriptions.push(
      this.coreService
        .getLTIAuthResponse(assessment.slug, {})
        .subscribe((res) => {
          window.open(res.url, '_blank');
        })
    );
  }
}
